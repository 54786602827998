import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../App';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/FileDownload';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import csvFileReader, { inputFileHandler } from '../../utils/csvFileReader';
import processCsv from '../../utils/processCsv';
import { CsvFileData } from '../../models/CsvFileData';
import trimString from '../../utils/trimString';
import b64EncodeUnicode from '../../utils/b64EncodeUnicode';
import createReactMaterialTableStructure from '../../utils/createReactMaterialTableData';
import createCsvFile from '../../utils/createCsvFile';
import createTxtFileBody from '../../utils/createTextFileBody';
import createTableHeaders from '../../utils/createTableHeaders';
import api from '../../api/api';
import createWebhookData from '../../utils/createWebhookData';
import checkLabName from '../../utils/checkLabName';
import checkCutoffs from '../../utils/checkCutoffs';
import sendSrcCsvToBucket from '../../utils/sendSrcCsvToBucket';
import { saveUploadInfoToFirestore, getEditsHistory } from '../../utils/firebaseHelper';
// import detectQcRun from '../../utils/detectQcRun';
import detectMixedResults from '../../utils/detectMixedResults';
import MESSAGES from '../../constants/infoMessages';
import TEST_TYPES from '../../constants/testTypes';
import INSTRUMENT from '../../constants/instrumentDataKeys';

interface TFilePicker {
  handleLoginOpen: Function;
  handleSNOpen: Function;
  setSNResolve: Function;
  setGetManSN: Function;
};

export default function FilePicker(props: TFilePicker) {
  const {
    appState: {
      csvData: {
        csvRawData,
        csvFileName,
        csvHeadersObj,
        tableDataArr,
        results,
        dataForApi,
        editsHistory: defaultEditsHistory,
        isQcResultEdited,
      },
      configUpdateTime,
      isLoading,
      userData,
      manualInstrumentSN,
    },
    appDispatch 
  } = useContext(AppContext);

  const {
    handleLoginOpen,
    handleSNOpen,
    setSNResolve,
    setGetManSN,
  } = props; 

  const [isCsvFileLoading, setLoading]  = useState(false);

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const [messageFromRAPI, setmessageFromRAPI] = useState('');

  useEffect(() => {
    api.rApi.updateConfig()
      .then(resp => {
        if (resp && resp.data) {
          const lastUpdatedTime = new Date(resp.data?.lastUpdated).toLocaleString();
          appDispatch({
            type: 'SET_UPDATE_CONFIG_TIME',
            payload: lastUpdatedTime,
          });
        };
      })
      .catch(err => {
        console.log(err);
        appDispatch({
          type: 'SET_ERROR',
          payload: err.message,
        }); 
      })
  }, [appDispatch]);

  const handleCSVFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const acceptedFiles = inputFileHandler(event);

    if (acceptedFiles) {
      csvFileReader(acceptedFiles, async ({csvFileName, csvFileBody}: CsvFileData) => {

        appDispatch({
          type: 'SET_LOADING',
          payload: true,
        });

        appDispatch({
          type: 'SET_MANUAL_INSTRUMENT_SN',
          payload: '',
        });

        // Send csvFileBody and csvFileName to AWS Bucker here
        const fileMd5Resp = await sendSrcCsvToBucket(csvFileName, csvFileBody)
          .catch(err => {
            console.log('Err', err.message);
            appDispatch({
              type: 'SET_ERROR',
              payload: err.message,
            })
          });

        // This is neccessery for extraction of all information from CSV Headers
        const localCsvFileData = processCsv(csvFileBody);
        const encodedToBase64Csv = b64EncodeUnicode(csvFileBody);

        const instrumentTypeResp = await api.firebase.detectInstrument(encodedToBase64Csv)
          .catch(err => {
            console.log('Err', err.message);
            appDispatch({
              type: 'SET_ERROR',
              payload: err.message,
            });
          });

        // console.log('InstrumentTypeResp', instrumentTypeResp);

        let parcedCsvResp: null | Record<string, any> = null;

        if (instrumentTypeResp.data && instrumentTypeResp.data?.instrumentType && instrumentTypeResp.data?.instrumentType !== 'Unknown') {
          parcedCsvResp = await api.firebase.convertCsv(encodedToBase64Csv, instrumentTypeResp.data.instrumentType)
            .catch(err => {
              console.log('Err', err.message);
              appDispatch({
                type: 'SET_ERROR',
                payload: err.message,
              });
            });
        } else {
          // STOP PROCESSING FILE if instrument type unknown
          appDispatch({
            type: 'SET_LOADING',
            payload: false,
          });
           return appDispatch({
            type: 'SET_ERROR',
            payload: 'Cannot detect instrument type',
          });
        };

        // console.log('!!parcedCsvResp!!', parcedCsvResp);

        let testMReactData = null;

        if (parcedCsvResp?.data?.res_dt && parcedCsvResp?.data?.sample_info) {
          testMReactData = createReactMaterialTableStructure(parcedCsvResp?.data?.res_dt);
        } else {
          // STOP PROCESSING FILE if file cannot be converted
          appDispatch({
            type: 'SET_LOADING',
            payload: false,
          });
  
          return appDispatch({
            type: 'SET_ERROR',
            payload: 'Cannot convert CSV file',
          });
        }

        // Need to Handle Err Parsing response from Firebase API
        if(!testMReactData) {

          appDispatch({
            type: 'SET_LOADING',
            payload: false,
          });

          return appDispatch({
            type: 'SET_ERROR',
            payload: 'Cannot convert instrument file',
          })
        };

        const {
          titlesObjArr,
          // tableDataArr,
        } = testMReactData as Record<string, any>;

        const localCsvHeadersObj = localCsvFileData ? localCsvFileData.csvHeadersObj : {};

        const csvHeadersObj = {
          ...localCsvHeadersObj,
          sampleType: parcedCsvResp?.data?.sample_info?.test_type,
          instrumentSN: parcedCsvResp?.data?.sample_info?.instrument_sn,
        };

        // Need to catch instrumentSN and instrumentType based on new logic
        let instrumentSN = '';
        instrumentSN = parcedCsvResp?.data?.sample_info?.instrument_sn || csvHeadersObj[INSTRUMENT.SN_KEY.CONCENRATION];
        const instrumentType = parcedCsvResp?.data?.sample_info?.instrument || csvHeadersObj[INSTRUMENT.TYPE];

        // If there is no instrument SN in src file
        // Trying to get previosly saved SN from local storage
        // if(manualInstrumentSN) {
        //   instrumentSN = manualInstrumentSN;
        // };

        // If there is no SN both in File & Local Storage
        // Open manual instrument entry dialog here
        // Opening SN Dialog and stop the main flow
        // till the number will be provided
        if(!instrumentSN) {
          await new Promise((resolve) => {
            console.log('Delay In');
            setSNResolve(resolve);
            handleSNOpen();
          });
    
          // console.log('Delay Out');
          // console.log('!!Manual Instrument SN', setGetManSN());

          const customInstrumentSN = setGetManSN();

          if(customInstrumentSN){
            instrumentSN = customInstrumentSN;
            // localStorageHelper.getSaveCustomSerialNumber(customInstrumentSN);

            appDispatch({
              type: 'SET_MANUAL_INSTRUMENT_SN',
              payload: customInstrumentSN,
            });
          };
        };

        if(!instrumentSN) {
          // TODO: Open manual instrument entry dialog here

          appDispatch({
            type: 'SET_LOADING',
            payload: false,
          });

          return appDispatch({
            type: 'SET_ERROR',
            payload: 'Instrument Serial Number not found',
          });
        };

        const labName = await api.firebase.getLabName(trimString(instrumentSN))
          .catch(err => {
            console.log(err);
            appDispatch({
              type: 'SET_ERROR',
              payload: err.message,
            });
          });

        if(checkLabName(labName?.data?.lab_name)) {
          appDispatch({
            type: 'SET_WARNING',
            payload: MESSAGES.LAB_UNKNOWN,
          });
        };

        const webhookData = createWebhookData(
          'File converted',
          instrumentType,
          csvHeadersObj.sampleType.toLowerCase(),
          csvFileName,
          instrumentSN,
          labName?.data?.lab_name || 'UNKNOWN',
          0,
          (userData?.email ? userData.email : 'N/A'),
          (new Date(Date.now()).toISOString()),
          200,
        );
        
        const tableDataArrContext = testMReactData.tableDataArr;

        webhookData.event = 'File Loaded';
        webhookData.number_rows = tableDataArrContext.length;

        let firebaseResp;
        let editsHistory;
        let fileMd5Hash = '';

        if(fileMd5Resp?.data) {
          const { data: fileMd5 } = fileMd5Resp;
          fileMd5Hash = fileMd5;

          firebaseResp = await saveUploadInfoToFirestore(fileMd5, csvFileName, userData?.email ? userData.email : 'N/A')
            .catch(err => {
              console.log(err);
            });
          
          editsHistory = await getEditsHistory(fileMd5)
            .catch(err => {
              console.log(err);
            });
        };

        // let isQcRun = false;
        const isQcRun = parcedCsvResp?.data?.sample_info?.qc_run;
        let isMixedresults = false;

        if(parcedCsvResp?.data?.sample_info?.test_type === TEST_TYPES.CONCENTRATION) {
          // isQcRun = detectQcRun(tableDataArrContext);
          webhookData.qc_run = isQcRun;

          if(!isQcRun) {
            isMixedresults = detectMixedResults(tableDataArrContext);
          };
        } else if(csvHeadersObj.sampleType.toLowerCase() === TEST_TYPES.MOLECULAR) {
          delete webhookData.qc_run;
        };

        // Creating {res_dt: [{},..], sample_info: {}} Structure
        // will be available after /api/v1/convert call

        let resultsTestData = null;

        if(!isMixedresults) {
          resultsTestData = {
            sample_info: {
              ...parcedCsvResp.data.sample_info,
              instrument_sn: instrumentSN,
              file_name: csvFileName,
              lab_name: labName?.data?.lab_name || 'UNKNOWN',
            },
            res_dt: parcedCsvResp.data.res_dt,
          };
        } else {
          appDispatch({
            type: 'SET_GENERIC_INFO_MESSAGE',
            payload: MESSAGES.MIXED_RESULTS_WARNING,
          });
        }

        // console.log('resultsTestData', resultsTestData);

        const resultsFromApi = resultsTestData ?
          await api.rApi.getResults(resultsTestData)
            .catch(err => {
              console.log('Err', err.message);

              webhookData.message = err?.response?.data?.error || err.message;
              webhookData.status_code = err.response.status;

              api.webhook.sendWebhook(webhookData)
                .then()
                .catch(err => console.log(err));

              // if 503 = Show Warning from err.response.data.error = "cannot determine molecular assay Id"
              if(err.response.status === 503) {
                appDispatch({
                  type: 'SET_WARNING',
                  payload: err?.response?.data?.error,
                })
              };

              appDispatch({
                type: 'SET_ERROR',
                payload: err.message,
              })
            }) : null;

      if(resultsFromApi) {
        webhookData.status_code = resultsFromApi.status;          
        api.webhook.sendWebhook(webhookData)
          .then()
          .catch(err => console.log(err));

        webhookData.event = 'File Sent to QC';
        const sentToQCresp: Record<string, any> = await api.firebase.sendToQc({
          sample_obj: {
            ...resultsFromApi.data.sample_obj
          },
        })
          .catch(err => {
            console.log('Err', err.message);

            webhookData.message = err.message;
            webhookData.status_code = err.response.status;

            // console.log('!!webhookData TO QC!!', webhookData);

            api.webhook.sendWebhook(webhookData)
              .then()
              .catch(err => console.log(err));
    
            appDispatch({
              type: 'SET_ERROR',
              payload: err.message,
            });
          })
          .finally(() => appDispatch({
            type: 'SET_LOADING',
            payload: false,
          }))

          // console.log('!! resp from FIREBASE API FOR QC!!', sentToQCresp);
      
          if(!sentToQCresp) {
            webhookData.message = 'No response from FB API';
            webhookData.status_code = 0;

            api.webhook.sendWebhook(webhookData)
              .then()
              .catch(err => console.log(err));

            appDispatch({
              type: 'SET_ERROR',
              payload: MESSAGES.QC_SEND_ERROR,
            })
          } else if(sentToQCresp.status !== 200 && sentToQCresp.status !== 202) {         
            webhookData.message = sentToQCresp.data?.error;
            webhookData.status_code = sentToQCresp.status;

            api.webhook.sendWebhook(webhookData)
              .then()
              .catch(err => console.log(err));

            appDispatch({
              type: 'SET_ERROR',
              payload: `${MESSAGES.QC_SEND_ERROR} ${sentToQCresp?.data?.error}`
            });
          } else {
              webhookData.message = sentToQCresp.data?.message;
              // We can hardcode 200 status here (As long as 202 goes to err in Slack bot)
              webhookData.status_code = 200;

              api.webhook.sendWebhook(webhookData)
                .then()
                .catch(err => console.log(err));
          }
        };

        if(!resultsFromApi) {
          appDispatch({
            type: 'SET_LOADING',
            payload: false,
          });
        };

        appDispatch({
          type: 'SET_FIRESTORE_UPLOAD_ID',
          payload: firebaseResp?.data?.uploadId || '',
        });

        // console.log('!!results!!', {resultsFromApi, webhookData});

        appDispatch({
          type: 'SET_CSV_DATA',
          payload: {
            csvRawData: csvFileBody,
            csvFileName,
            csvHeadersObj,
            titlesObjArr: testMReactData?.titlesObjArr || titlesObjArr,
            tableDataArr: tableDataArrContext,
            dataForApi: resultsTestData,
            results: resultsFromApi ? resultsFromApi.data : resultsFromApi,
            editsHistory: editsHistory ? editsHistory.data : defaultEditsHistory,
            fileMd5: fileMd5Hash,
            isQcResultEdited: false,
          },
        });
      });
    }
  };

  const sendResultsToLisFb = async () => {
    if(!results || !csvHeadersObj) return;

    const instrumentType = csvHeadersObj['Instrument Type'];

    const webhookData = createWebhookData(
      'File Sent to LIS',
      instrumentType,
      results?.sample_obj?.sampleType.toLowerCase() || 'N/A',
      csvFileName,
      results?.sample_obj?.instrumentSn || 'No Serial Number',
      results?.sample_obj?.labName || 'N/A',
      tableDataArr.length,
      (userData?.email ? userData.email : 'N/A'),
      (new Date(Date.now()).toISOString()),
      200,
    );

    const isQcRun = dataForApi?.sample_info?.qc_run;
    const testType = dataForApi?.sample_info?.test_type;

    // if(csvHeadersObj.sampleType.toLowerCase() === TEST_TYPES.CONCENTRATION) {
    if(testType === TEST_TYPES.CONCENTRATION) {
      webhookData.qc_run = isQcRun;
    } else if(testType === TEST_TYPES.MOLECULAR) {
      delete webhookData.qc_run;
    };

    const resp = await api.firebase.sendToLis({
      sample_obj: {
        ...results.sample_obj,
      }
    })
    .catch(err => {
      console.log('Err', err.message);

      webhookData.message = err.message;
      webhookData.status_code = err.response.status;
      api.webhook.sendWebhook(webhookData)
        .then()
        .catch(err => console.log(err));

      appDispatch({
        type: 'SET_ERROR',
        payload: err.message,
      })
    })

    const uploadId = resp?.data?.uploadId;

    if (!uploadId) {
      return appDispatch({
        type: 'SET_ERROR',
        payload: 'No upload ID from FB API',
      });
    }
   
    setLoading(true);

    // Starting Long Poll requests to Firebase API using LIS Upload Info
    let lisResp = await api.firebase.getLisUploadStatus(uploadId)
      .catch((err) => {
        if (err.response) {
          webhookData.status_code = err.response.status;
          webhookData.message = err.response.data;
        } else if (err.request) {
          webhookData.status_code = err.requset.code;
          webhookData.message = 'No response from FB API'
        }   
      });
    
    while (lisResp?.status !== 200) {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      // console.log('LIS RESP', lisResp.data);
      lisResp = await api.firebase.getLisUploadStatus(uploadId)
        .catch((err) => {
          if (err.response) {
            webhookData.status_code = err.response.status;
            const errRespArr = err.response?.data?.uploadInfo?.lisResponse;
            let errInfo = '';
            if (Array.isArray(errRespArr)) {
              errInfo = errRespArr[0]?.lisResponse;
            }
            webhookData.message = errInfo;
          } else if (err.request) {
            webhookData.status_code = err.request.code;
            // webhookData.message = 'No response from FB API'
          }   
        });

      if (lisResp?.status !== 202) {
        break;
      }
    }

    if (lisResp?.data) {
      // Got response from LIS transferred thru Firebase API
      // console.log('!!!LIS Resp Data!!!', lisResp.data);
      const responsesArr = lisResp.data?.uploadInfo?.lisResponse;

      // let lastResponseFromLis = {
      //   code: '0',
      //   status: 'No response from LIS',
      //   lisCode: 0,
      //   lisStatus: 'N/A',
      // };

      let lastResponseFromLis: Record<string, any> = {};

      if (Array.isArray(responsesArr)) {
        // Got response from LIS and there is information to display
        lastResponseFromLis = responsesArr[responsesArr.length - 1];
      };
      if (lastResponseFromLis?.code !== '200') {
        // Got response from LIS but something went wrong
        appDispatch({
          type: 'SET_ERROR',
          payload: `LIS Code: ${lastResponseFromLis?.code || lastResponseFromLis?.lisCode}. Status: ${lastResponseFromLis?.status || lastResponseFromLis?.lisStatus}`,
        });
      } else {
        // Got response from LIS and everything is Ok
        setmessageFromRAPI(lastResponseFromLis?.status || '');
        setConfirmOpen(true);
      }
      webhookData.status_code = parseInt(lastResponseFromLis?.code);
      webhookData.message = lastResponseFromLis?.status; 
    } else {
      // Didn't get response data from Firebase API
      webhookData.message = webhookData.message || 'Didn\'t get response data from Firebase API';

      appDispatch({
        type: 'SET_ERROR',
        payload: 'No response from LIS',
      });
    }

    // console.log('!!WEBHOOKDATA', webhookData);
    api.webhook.sendWebhook(webhookData)
      .then()
      .catch(err => console.log(err));

    setLoading(false);
  }

  const downloadCsvFile = () => {
    if(!results?.sample_obj?.results) return;

    const tableHeaders = createTableHeaders(results.sample_obj.results[1]);
    const csvFile = createCsvFile(csvHeadersObj, tableHeaders, results.sample_obj.results);
    const textFileBlob = createTxtFileBody(csvFile);

    if(textFileBlob) {
      const downloadCsvFileUrl = window.URL.createObjectURL(textFileBlob);
      const link = document.createElement('a');
      link.href = downloadCsvFileUrl;
      link.download = `${csvFileName.replace('.csv', '')}_results.csv`;
      link.click();
      URL.revokeObjectURL(link.href);
    } else {

      return;
    };  
  };

  const loginAlert = () => handleLoginOpen();

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={!!(isLoading && csvRawData)}
      >
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isConfirmOpen}
        autoHideDuration={6000}
        onClose={() => setConfirmOpen(false)}
      >
        <Alert variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {`File Successfully Sent! ${messageFromRAPI}`}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 0 8px',
        }}
      >
        <Typography
          variant="subtitle2"
          component="span"
          sx={{
            flexGrow: 1,
          }}
        >
          Upload Instrument CSV result file
        </Typography>
        {Boolean(configUpdateTime) && <Typography 
          variant="body2"
          component="span"
          sx={{
            paddingRight: 1
          }}
        >
          {`Config file updated at: ${configUpdateTime}`}
        </Typography>}
        {Boolean(manualInstrumentSN) ? 
          <Typography
            variant="body2"
            component="span"
            color="warning"
            sx={{
              paddingRight: 1
            }}
          >
            Instrument S/N (CUSTOM): {manualInstrumentSN}
          </Typography> : <Typography
            variant="body2"
            component="span"
            sx={{
              paddingRight: 1
            }}
          >
            Instrument S/N: {
              (csvHeadersObj[INSTRUMENT.SN_KEY.MOLECULAR] && trimString(csvHeadersObj[INSTRUMENT.SN_KEY.MOLECULAR])) ||
              (csvHeadersObj[INSTRUMENT.SN_KEY.CONCENRATION] && trimString(csvHeadersObj[INSTRUMENT.SN_KEY.CONCENRATION])) ||
              'N/A'
            }
          </Typography>
        }
        <Typography variant="body2" component="span">
          {`Sample Type: ${csvHeadersObj.sampleType || 'N/A'} `}
        </Typography>
      </Box>
      <Stack
        direction="row"
        sx={{
          padding: '0 0 24px',
        }}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            flexGrow: 1,
          }}
        >
          <Button
            variant="contained"
            disableElevation
            component="label"
            sx={{
              height: '100%',
              borderRadius: '4px 0 0 4px',
            }}
            disabled={isLoading}
            onClick={!userData ? loginAlert : undefined}
          >
            Browse
            {Boolean(userData) && <input hidden type="file" onChange={handleCSVFile} />}
          </Button>
          <TextField
            size="small"
            variant="outlined"
            value={csvFileName}
            id="file-name"
            disabled
            sx={{
              '& fieldset': {
                borderRadius: '0 5px 5px 0',
                borderLeft: 'none',
              }
            }}
          />
          {Boolean(results?.sample_obj?.assay_id) && (
            <Typography
                variant="subtitle2"
                component="div"
                sx={{
                  display: 'inline-block',
                  marginLeft: 1,
                  border: '1px solid grey',
                  borderRadius: '4px',
                  padding: '8.5px 14px',
                }}
              >
                {`Assay ID: ${results?.sample_obj?.assay_id ? results.sample_obj.assay_id.toUpperCase() : 'N/A'}`}
              </Typography>
            )}
          {Boolean(results?.sample_obj?.labName) && (
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                display: 'inline-block',
                marginLeft: 1,
                border: '1px solid grey',
                borderRadius: '4px',
                padding: '8.5px 14px',
              }}
            >
              {`Laboratory: ${results?.sample_obj?.labName}`}
            </Typography>  
          )}
          {Boolean(results?.cutoffs?.[0]) && (
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                display: 'inline-block',
                marginLeft: 1,
                border: '1px solid grey',
                borderRadius: '4px',
                padding: '8.5px 14px',
              }}
            >
              {`Cutoffs: ${checkCutoffs(results?.cutoffs[0])}`}
            </Typography>  
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Button
            disabled={!results || isCsvFileLoading || checkLabName(results?.sample_obj?.labName) || isQcResultEdited}
            color="primary"
            variant="contained"
            disableElevation
            sx={{
              marginRight: 1,
            }}
            endIcon={isCsvFileLoading ?
              <CircularProgress
                color="inherit"
                size={20}
              /> :
              <SendIcon />
            }
            onClick={checkLabName(results?.sample_obj?.labName) ? undefined : sendResultsToLisFb}
          >
            Send to Beacon LIS
          </Button>
          <Button
            disabled={!results?.sample_obj?.results}
            color="success"
            variant="contained"
            disableElevation
            endIcon={<DownloadIcon />}
            onClick={downloadCsvFile}
          >
            Download file
          </Button>
        </Box>
      </Stack>
    </>
  );
}