import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { AppContext } from '../../App';
import { TAppHeader } from './TAppHeader';
import localStorageHelper from '../../utils/localStorageHelper';
import appVersion from '../../constants/appVersion';


export default function ButtonAppBar(props: TAppHeader) {
    const { handleHelpClick, handleLoginOpen } = props;

    const {
      appState: {
        userData,
      },
      appDispatch,
    } = useContext(AppContext);

    const userLogout = () => {
      localStorageHelper.removeUserData();
      appDispatch({
        type: 'SET_USER_DATA',
        payload: null,
      });
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {`Pathlight Web - ${appVersion}`}
            </Typography>
            {userData && <Button
              onClick={handleHelpClick}
              color="inherit"
            >
              Help
            </Button>}
            {userData ? (
            <>
              <Button
                onClick={userLogout}
                color="inherit"
              >
                Logout
              </Button>
              <Avatar
                variant="square"
                alt={userData.username}
                sx={{
                  marginLeft: 1,
                }}
              >
                {userData.username[0].toLocaleUpperCase()}
              </Avatar>
            </>) : 
            <Button
              onClick={handleLoginOpen}
              color="inherit"
            >
              Login
            </Button>}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }