const ENDPOINTS = {
  BEACON_LIS: {
    BASE_URL: process.env.REACT_APP_BEACON_LIS_BASE_URL,
    // BASE_URL: 'https://sandbox.lis.lighthouselabservices.com/beacon',
    // BASE_URL: 'https://dev.lis.lighthouselabservices.com/beacon',
    AUTH_URL: 'api/login',
    GET_USER_DATA: 'api/v1/user/userProfile'
  },
  R_API: {
    BASE_URL: process.env.REACT_APP_R_API_BASE_URL,
    // BASE_URL: 'https://pathlight.api.lighthouselabservices.com',
    ASSAY: 'v1/assay',
    FORMAT: 'v1/format',
    LAB_NAME: 'v1/labname',
    RESULTS: 'v1/results',
    SEND_SRC_FILE: 'v1/save',
    SEND_TO_QC: 'v1/tobeaconqc',
    SEND_TO_LIS: 'v1/tobeacon',
    UPDATE_CONFIG: 'v1/update',
    CONVERT_FOR_SHIMADZU: 'v1/convert',
    TO_BEACON_QC: 'v1/tobeaconqc',
  },
  FIREBASE: {
    FUNCTIONS_BASE_URL: process.env.REACT_APP_GCLOUD_FUNC_BASE_URL,
    // FUNCTIONS_BASE_URL: 'https://us-central1-lh-pathlight.cloudfunctions.net',
    // FUNCTIONS_BASE_URL: 'http://localhost:5000/lh-pathlight/us-central1',
    SAVE_UPLOAD: 'api/upload',
    SAVE_EDIT_INFO: 'api/edit',
    GET_EDITS: 'api/file',
    DETECT_INSTRUMENT: 'api/v1/detect',
    CONVERT_CSV: 'api/v1/convert',
    GET_LAB_NAME: 'api/v1/getlabname',
    SEND_TO_LIS: 'api/v1/sendtolis',
    GET_LIS_UPLOAD_STATUS: 'api/v1/getlisuploadstatus',
    TO_BEACON_QC: 'api/v1/tobeaconqc',
  },
  ZENDESK: {
    BASE_URL: process.env.REACT_APP_ZENDESK_BASE_URL,
    // BASE_URL: 'https://lighthouselabs.zendesk.com',
    CREATE_TICKET: 'api/v2/tickets.json',
    CREATE_REQUEST: 'api/v2/requests.json',
  },
  WEBHOOK_URL: process.env.REACT_APP_WEBHOOK_URL,
  // WEBHOOK_URL: 'https://hook.us1.make.com/coqehwgg5izptfsefw3qbqaxv9q21obz',
};

export default ENDPOINTS;