import { AxiosInstance } from 'axios';
import URLS from '../../constants/urls';
import {
  UploadInfoModel,
  EditInfoModel,
} from '../../models/FirebaseModels';

const {
  FIREBASE: { 
    SAVE_UPLOAD,
    SAVE_EDIT_INFO,
    GET_EDITS,
    DETECT_INSTRUMENT,
    CONVERT_CSV,
    GET_LAB_NAME,
    SEND_TO_LIS,
    GET_LIS_UPLOAD_STATUS,
    TO_BEACON_QC,
  }
} = URLS;

const firebase = (httpClient: AxiosInstance) => ({
  saveUpload(requestBody: UploadInfoModel): Promise<any> {

    return httpClient.post(`/${SAVE_UPLOAD}`, requestBody);
  },
  saveEdit(requestBody: EditInfoModel): Promise<any> {

    return httpClient.post(`/${SAVE_EDIT_INFO}`, requestBody);
  },

  getEdits(fileMd5: string): Promise<any> {

    return httpClient.get(`/${GET_EDITS}/${fileMd5}`);
  },

  detectInstrument(base64string: string): Promise<any> {

    return httpClient.post(`/${DETECT_INSTRUMENT}`, {
      textB64: base64string,
    });
  },

  convertCsv(base64string: string, instrumentType: string): Promise<any> {

    return httpClient.post(`/${CONVERT_CSV}`, {
      textB64: base64string,
      instrumentType,
    });
  },

  getLabName(instrumentSn: string): Promise<any> {

    return httpClient.get(`/${GET_LAB_NAME}/${instrumentSn}`);
  },

  sendToLis(lisResultsObj: Record<string, any>): Promise<any> {

    return httpClient.post(`/${SEND_TO_LIS}`, lisResultsObj);
  },

  getLisUploadStatus(uploadId: string): Promise<any> {
    
    return httpClient.get(`/${GET_LIS_UPLOAD_STATUS}/${uploadId}`);
  },

  sendToQc(lisResultsObj: Record<string, any>): Promise<any> {

    return httpClient.post(`/${TO_BEACON_QC}`, lisResultsObj);
  },
});

export default firebase;
