export default function createCsvFile(csvHeadersObj: Record<string, any> | null, titlesObjArr: Record<string, any>[], tableDataArr: Record<string, any>[]): string {
  const titlesString = titlesObjArr.map(titleObj => titleObj.header).join(',');

  // const csvDataRowsArr = tableDataArr.map(rowObj => Object.values(rowObj));

  // const csvDataTextRows = csvDataRowsArr.reduce((acc: string, value: string[]) => {
  //   const dataRow = value.join(',').concat('\n');
  //   return acc.concat(dataRow)
  // }, '');

  let csvHeaderRows: string[] = [];

  if(csvHeadersObj) {

    const csvHeaderValues = Object.entries(csvHeadersObj);
    csvHeaderRows = csvHeaderValues.map(headerValues => {
      headerValues[0] = headerValues[0].concat(':');
      headerValues.unshift('#');
      
      return headerValues.join(' ');
    });
    
  };

  const csvDataTextRows = tableDataArr.map((rowObj: Record<string, any>): string => {
    const csvDataRowStringsArr = titlesObjArr.reduce((acc: any[], titleObj: Record<string, any>): any[] => {
      acc.push(rowObj[titleObj.accessorKey]);
      return acc;
    }, []);
    return csvDataRowStringsArr.join(',');
  });

  const csvDataWithTitlesArr = [...csvHeaderRows, ...[titlesString], ...csvDataTextRows];

  return csvDataWithTitlesArr.join('\n');
};